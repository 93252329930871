.qna-qrcode-scanner {

    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0.25rem 0.75rem;

        &__info-text{
            font-size: 18px;
            font-weight: 600;
            margin: 1rem;
            text-align: center;
        }

        &__scanner{
            width: 90% !important;
            max-width: 900px;
            border-radius: 8px;
        }
        .jme-duration-progress-widget{
            .progress-bar{
                background-color: var(--qna-progress-bar) !important;
            }
        }
        .jme-duration-progress-widget--text{
            display: none;
        }
    }

    &__msg-container{ 
        width: 95%;
        border-radius: .125rem;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        color: white;
        padding: .5rem;
        text-align: center;
        &--loading{
            background-color: gray !important;
        }

        &--success{
            /*background-color: #018e42;*/
            color: #018e42;
            background-color: rgba(1, 142, 66, .3);
            .msg-container-header{
                border-color: #018e42 !important;
            }
            
        }

        &--error{
            color: #bf1a2f;
            background-color: rgba(191, 26, 47, .25);
            .msg-container-header{
                border-color: #bf1a2f !important;
            }
        }

        &--warning{
            color: #664d03;
            background-color: #fff3cd;
            .msg-container-header{
                border-color: #664d03 !important;
            }
        }

        &--secondary{
            color: #141414;
            background-color: #f4f4f4;
            .msg-container-header{
                border-color: #141414 !important;
            }
        }
        &--info{
            color: #041b37;
            background-color: rgba(46, 133, 239,.3);
            .msg-container-header{
                border-color: #041b37 !important;
            }
        }

        &--size-small{
            .msg-container-content{
                font-size: 1rem !important;
                p{margin-bottom: .75rem;}
            }
        }


        .msg-container-header{
            font-size: 1.25rem;
            font-weight: bold;
            padding-bottom:.5rem ;
            border-bottom: 1px solid var(--qna-secondary);
            text-align: center;
        }
        .msg-container-content{
            margin: .95rem 0;
            font-size: 1.3rem;
        }
    }
}