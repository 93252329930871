:root{

    --main-bg-color:#fff;
    --main-text-color:#000;

    --btn-primary-bg-color:#041b37;
    --btn--primary-color:#fff;

    --btn-secondary-bg-color:#f4f4f4;
    --btn-secondary-color:#041b37;

    --qna-rooms-list-color: #ffffff;
    --qna-rooms-list-bg: #041b37;
    --qna-btn-primary-bg: #041b37;
    --qna-btn-primary-color: #ffffff;
    --qna-btn-secondary-bg: #f4f4f4;
    --qna-btn-secondary-color: #041b37;
    --qna-comments-color: #111111;
    --qna-comments-bg: #ceebff;

    --qna-progress-bar:#0E5DBE;
    --qna-progress-bar-bg:#EBEBEB;

    --qna-success:#018e42;
    --qna-error:#bf1a2f;
    --qna-warning:#F7D002;
    --qna-secondary:#f4f4f4;

    --qna-error2:#E22840;

    --qna-footer-height:56px;
    --qna-main-menu-active-border-color:#041b37;
    --qna-label-red:#E22840;
}
body{
    color: #000;
    background: var(--main-bg-color);
}

.qna-v2-main-container{
    /*qna colors*/
    --jme-btn-primary-bg: var(--qna-btn-primary-bg);
    --jme-btn-primary-color: var(--qna-btn-primary-color);

    --jme-qna-btn-primary-bg: var(--qna-btn-primary-bg);
    --jme-qna-btn-primary-color: var(--qna-btn-primary-color);

    --jme-btn-secondary-bg : var(--qna-btn-secondary-bg);
    --jme-btn-secondary-color: var(--qna-btn-secondary-color);

    &.has-main-menu{
        margin-bottom: var(--qna-footer-height, 56px);
    }

    .jme-voting-widget__vote-now{
        background-color:var(--qna-btn-primary-bg);
        color: var(--qna-btn-primary-color);
        font-weight: bold;
    }
    .jme-qna-widget .card-body{
        padding: .5rem;
    }
    .jme-session-countdown .progress{
        background-color: var(--qna-progress-bar-bg) !important;
        .progress-bar{
            background-color: var(--qna-progress-bar) !important;
            
        }
    }
    .btn-primary{
        background-color: var(--qna-btn-primary-bg);
        color: var(--qna-btn-primary-color);
    }
    .btn-primary-outline{
        color: var(--qna-btn-primary-bg);
        background-color: #f4f4f4;
        border: 1px solid var(--qna-btn-primary-bg);
    }

    &__app-content{
        width: 100vw;
        height: 100vh;
        display: flex;
        &.loading{
            align-items: center;
            justify-content: center,
        }
        
    }
    p.bold{font-weight: bold;}


}
.jme-qna-disabled{
    opacity: .57 !important;
    pointer-events: none !important;
}
.modal-login-sucess {
    footer {
        .btn-outline {
            display: none;
        }
    }
}

.jme-modal-container {
    z-index: 1001;
}