.qna-user-tracked-sessions{
    display: flex;
    flex-direction: column;
    gap: .125rem;
    margin-top: 1rem;
    &__item{
        display: flex;
        background-color:#EBEBEB;
        padding: .25rem .5rem;
        justify-content: space-between;
        align-items: center;
        .datetime,
        .label{
            font-size: .85rem;
        }
        .label{
            &--red{
                color: var(--qna-label-red, red);
            }
        }
    }
    &__right{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: .25rem;
        button{
            font-size: .95rem;
        }
    }
    
}