.qna-user-tracking{
    padding: 0.25rem 0.75rem;
    .qna-qrcode-scanner__msg-container{
        width: 100%;
    }
    &__scan-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.5rem;

        &__button{
            background-color: var(--btn-primary-bg-color);
            color: var(--btn--primary-color);
            border: none;
            outline: none;
            border-radius: var(--jme-qna-border-radius);
            padding: .3rem;
            width: 30%;
            margin-top: .5rem;
        }
    }
}