.Tabs {
    width: 90%;
    padding: 2rem 1rem;
    border-radius: 2rem;
    overflow-x: hidden;
    @media (max-width: 769px) {
       padding: 1rem 0;
     
    }

    &__imgHolder{
      display:flex;
      align-items: center;
      justify-content: center;
      

      img{
        max-height:100px;
        max-width: 300px;
        object-fit: contain;
      }
    }

    .title{
      width: 100%;
      margin-bottom: .5rem;
      display:flex;
      align-items: center;
      justify-content: center;

        span{
          font-size:1.2rem;
          font-weight: 600;
        }
    }

   

    .back-btn-container{
      margin-bottom:1.5rem;
      display:flex;
      
      justify-content: space-between;

      &__backButton{
        display: flex;
        flex-shrink: 0;
        align-items: center;

        i{
          font-size: 1.5rem;
        }

        span{
          font-size:1rem;
        }
      }

      &__loginOutButton{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        i{
          font-size:1.5rem;
        }
        
      }
    }

    .qna-audio-only-content {
      text-align: right;

      a {
        background-color: var(--jme-btn-secondary-bg);
        color: var(--jme-btn-secondary-color);
        display: inline-block;
        padding: 0.5rem;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }

        i {
          padding-left: 0.5rem;
          font-size: 1.25rem;
        }
      }
    }
    
    .qna-at-stream-content{
      text-align: right;
      margin-top: -.5rem;
      .toogle-btn{
        background-color: var(--jme-btn-secondary-bg);
        color: var(--jme-btn-secondary-color);
        display: inline-block;
        padding: .5rem;
        cursor: pointer;
        &.active{
          background-color: var(--qna-btn-primary-bg);
          color: var(--qna-btn-primary-color);
          &:hover{
            background-color: #1f3c62;
            transition: background-color 0.5s ease;
            
          }
        }
        i{
          padding-left: .5rem;
          font-size: 1.25rem;
        }
      }
      .player-container{
        margin-top: .75rem;
        margin-bottom: .75rem;
        transition: all 0.5s ease;
      }
    }
    .jme-stream-player-widget--container>div.small{
      z-index: 100;
    }
    .tab-info-msg,
    .jme-qna-widget__tab-info-msg {
      font-size: 1.3rem;
      text-align: center;
      margin-top: 3rem;
      &.type-flex{
        text-align: unset;
        margin:unset;

        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
      }
    }

    &__nav{
        
        width: 60%;
        margin: 0 auto 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
        padding-left: 0px;
        @media (max-width: 768px) {
          width: 98%;
        }

        li{
            width: 100%;
            padding: 1rem;
            list-style: none;
            text-align: center;
            cursor: pointer;
            transition: all 0.5s;
            display:flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            
            font-size: 1rem;
            background-color: var(--qna-btn-secondary-bg);
            color: var(--qna-btn-secondary-color);


            &.Tabs__nav--active{
              background-color: var(--qna-btn-primary-bg);
              font-weight:800;
              color:var(--qna-btn-primary-color);
            }

            .jme-badge{
              display: flex;
              align-items: center;
              justify-content: center;
              width:1.5rem;
              height: 1.5rem;
              background-color: red;
              font-size: .8rem;
              font-weight: bold;
              color: #fff;
              margin-left: .5rem;
              padding: 2px;
              border-radius: 3px;
              span.text{
            
              }
            }

            i{
              margin-left: .5rem;
              color:orange;
              animation: blinker 2s linear infinite;
              font-size: 1rem;
            }         
        }
    }

    &__outlet{
        overflow-y: auto;
        overflow-x: hidden;

        &__evaluation{
          display: flex;
          align-items: center;
          justify-content: center;
          padding:15px 10px 0;
          .tab-info-msg{
            margin-top: calc(3rem - 15px);
            text-align: center;
          }

          .evaluation-container{
            overflow-x: hidden;
          }

          span{
            font-size:1.0625rem
          }

          &__button{
            background-color: var(--qna-btn-primary-bg);
            color:var(--qna-btn-primary-color);
          }
        }
    }
   }

   @keyframes blinker {
    0% {
      opacity: 0;
    }
    
    50% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
   }