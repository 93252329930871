.qna-home-tabs-container{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    background-color: #ebebeb;
    &__items {
        display: flex;
        align-items: center;
        gap: .125rem;
        max-height: var(--qna-footer-height, 56px);
        border-top: 1px solid #ebebeb;
    }

    .qna-home-tab {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--btn-primary-bg-color);
        background-color: var(--qna-btn-secondary-bg);
        padding: .5rem 1rem;
        cursor: pointer;
        &:active{transform: scale(0.95); /* Slightly scale down when clicked */}
        &--active::before{
            // background-color: var(--btn-primary-bg-color);
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            transform: translateY(4px);
            height: 5px;
            transition: transform 300ms ease-out;
            transform: translateY(0);
            background-color: var(--qna-main-menu-active-border-color);
            z-index: 10;
        }
        span{
            font-size: .85rem;
        }
        i{font-size: 1.35rem;}
    }

    .qna-badge{
        position: absolute;
        background-color: #E22840;
        color: #fff;
        display: inline-block;
        padding: 0 .35rem;
        border-radius: 50%;
        top:-2px;
        right: .25rem;
        font-weight: bold;

    }

}
